<template>
  <router-link class="Project" :to="{ name: 'project_details', params: { project_id: project.projectId }}">
    <span class="Project__name" >
      {{project.name}}
    </span>
    <div class="Project__application-types" >
      <img v-if="hasAndroidApplication" class="Project__application-icon"
        src="@/assets/img/android-icon.svg"/>
      <img v-if="hasIosApplication" class="Project__application-icon"
        src="@/assets/img/ios-icon.svg"/>
      <img v-if="hasWebApplication" class="Project__application-icon"
        src="@/assets/img/web-icon.svg"/>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'project',
  props: ['project'],
  computed: {
    applicationTypes () {
      const applicationTypes = this.project.toJSON().applicationTypes
      if (applicationTypes) {
        return applicationTypes
      }
      return []
    },
    hasAndroidApplication () {
      return this.applicationTypes.indexOf('android') > -1
    },
    hasIosApplication () {
      return this.applicationTypes.indexOf('ios') > -1
    },
    hasWebApplication () {
      return this.applicationTypes.indexOf('web') > -1
    }
  }
}
</script>

<style lang="scss">
@import "./Project.scss";
</style>
