<template>
 <transition name="NewProjectModal__fade">
    <div v-if="open" @click.stop="cancel()" class="NewProjectModal">
      <div @click.stop class="NewProjectModal__content">
        <div class="NewProjectModal__header" >
          <div class="NewProjectModal__title" >
            Add New Project
          </div>
        </div>
        <div class="NewProjectModal__body">
          <span>Name</span>
          <div class="NewProjectModal__error" v-if="!$v.name.required">
            Field required!
          </div>
          <input class="NewProjectModal__input" type="text" v-model="name">
          <span>Email</span>
          <div class="NewProjectModal__error" v-if="!$v.email.required">
            Field required!
          </div>
          <div class="NewProjectModal__error" v-if="!$v.email.email">
            Must be a proper email!
          </div>
          <input class="NewProjectModal__input" type="email" v-model="email">
        </div>
        <div class="NewProjectModal__footer">
          <button
            @click.stop="cancel()"
            :disabled="busy"
            class="NewProjectModal__button-warning"
            :class="{ 'NewProjectModal__button-disabled': busy }"
          >
            Cancel
          </button>
          <button
            @click.stop="addProject()"
            :disabled="buttonDisabled"
            class="NewProjectModal__button"
            :class="{ 'NewProjectModal__button-disabled': buttonDisabled }"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
 </transition>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  name: 'new_project_modal',
  props: ['open'],
  data () {
    return {
      name: null,
      email: null,
      busy: false
    }
  },
  methods: {
    cancel () {
      this.name = null
      this.email = null
      this.$emit('close-modal')
    },
    addProject () {
      this.busy = true
      this.$store
        .dispatch('createDeveloperProject', {
          email: this.email,
          name: this.name,
          userId: this.currentUser.userId
        })
        .then(() => {
          this.busy = false
          this.name = null
          this.email = null
          this.$emit('close-modal')
        })
    }
  },
  computed: {
    buttonDisabled () {
      return this.busy || this.$v.$invalid
    },
    ...mapGetters(['currentUser'])
  },
  validations: {
    name: {
      required
    },
    email: {
      required,
      email
    }
  }
}
</script>

<style lang="scss">
@import "./NewProjectModal.scss";
</style>
