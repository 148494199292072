<template>
  <div class="ProjectsBar">
    <span class="ProjectsBar__title" >
      Projects
    </span>
    <button @click="openNewProjectModal()" class="ProjectsBar__button" >Add new project</button>
  </div>
</template>

<script>
export default {
  name: 'projects_bar',
  methods: {
    openNewProjectModal () {
      this.$emit('new-project-modal')
    }
  }
}
</script>

<style lang="scss">
@import "./ProjectsBar.scss";
</style>
