<template>
  <div class="Projects">
    <new-project-modal
      :open="newProjectModal"
      @close-modal="closeNewProjectModal()"
    />
    <div class="Projects__bar">
      <projects-bar
        @new-project-modal="openNewProjectModal()"
      />
    </div>
    <div class="Projects__list">
      <project v-for="(project, index) in developerProjects" :project="project" :key="index" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Project from '@/components/projects/Project'
import ProjectsBar from '@/components/projects/ProjectsBar'
import NewProjectModal from '@/components/projects/NewProjectModal'

export default {
  name: 'projects',
  data () {
    return {
      newProjectModal: false
    }
  },
  computed: mapGetters(['developerProjects']),
  methods: {
    openNewProjectModal () {
      this.newProjectModal = true
    },
    closeNewProjectModal () {
      this.newProjectModal = false
    }
  },
  mounted () {
    this.$store.dispatch('getDeveloperProjects')
  },
  components: {
    Project,
    ProjectsBar,
    NewProjectModal
  }
}
</script>

<style lang="scss" scoped>
@import "./Projects";
</style>
